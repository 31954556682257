<template>
  <div class="mb-10" xs="12" 
  lg="6"
  sm="5" 
  md="4">
    <div v-if="isLoading">
      <v-sheet height="100vh">
        <div class="flex">
          <div class="flex flex-column">
            <div class="flex justify-center">
              <v-img
                max-width="600"
                :src="require('@/assets/img/await.svg')"
              ></v-img>
            </div>
            <span class="text-center text-h5 green--text mt-3">
              Wrapping up your order
            </span>
            <div class="flex justify-center my-3">
              <v-progress-circular
                indeterminate
                color="green"
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
    <div v-else class="flex flex-column">
      <v-dialog
        v-model="dialog"
        scrollable
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title> Are you sure ? </v-card-title>
          <v-card-text class="black--text">
            You're about to change outlet with items in your cart. Proceeding
            will remove any item from the cart.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="leaf" text @click="dialog = false"> Disagree </v-btn>
            <v-btn color="leaf" text @click="change(selectedOutlet, true)">
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <h1 class="font-weight-bold nunito ml-3 center">{{ franchise.name }}</h1>
      <!-- search seaction -->
      <v-btn color="black" icon @click="searchIsActive">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <transition name="fade">
        <div v-if="searchMode">
          <v-text-field
            color="leaf"
            class="mx-5"
            v-model="searchWord"
            @keyup="search"
          >
            <template v-slot:label> Search product </template>
          </v-text-field>
          <div
            class="my-2"
            v-for="(value, index) in searchProduct"
            :key="index"
          >
            <transition name="fade">
              <v-card
                class="mt-2 mb-5"
                elevation="0"
                @click="selectProduct(value)"
                :disabled="
                  value.total_left == 0 || value.out_of_stock ? true : false
                "
              >
                <v-divider class="my-2"></v-divider>
                <v-row class="my-2">
                  <v-col
                    cols="5"
                    lg="2"
                    class="pa-1 pl-4"
                    v-if="value.media_urls.length > 0"
                  >
                    <v-img
                      max-height="120"
                      max-width="140"
                      :src="image(value.media_urls[0])"
                    ></v-img>
                  </v-col>
                  <v-col cols="5" class="pl-3 flex flex-column">
                    <h4 class="nunito font-weight-bold">{{ value.name }}</h4>
                    <h5 class="lato red--text">Favourite</h5>
                    <span class="flex">
                      <h6 class="lato font-weight-bold mt-1">RM</h6>
                      <h4 class="lato font-weight-bold ml-1">
                        {{ value.unit_price }}
                      </h4>
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </transition>
          </div>
        </div>
      </transition>
      <!-- end of search section -->
      <h4 class="nunito ml-3" v-if="franchise.outlets.length == 0">
        {{ splited ? splited : "" }}
      </h4>
      <v-select
        class="ml-3"
        style="width: 50%"
        color="leaf"
        item-color="leaf"
        v-if="franchise.outlets.length > 1"
        :items="outlets"
        :value="out"
        @change="change($event, false)"
      ></v-select>
    </div>
    <div class="mt-2">
      <!-- <v-alert color="leaf" outlined icon="mdi-alpha-g-circle">
        Welcome to the new GetOrders V2 Beta Version app
      </v-alert> -->
      <v-alert
        color="blue-grey"
        outlined
        icon="mdi-bullhorn-outline"
        v-if="franchise.announcement && franchise.announcement !== ''"
      >
        {{ franchise.announcement }}
      </v-alert>
      <!-- <v-alert
        color="indigo"
        outlined
        icon="mdi-alpha-i-circle"
        v-if="franchise.custom_message && franchise.custom_message !== ''"
      >
        {{ franchise.custom_message }}
      </v-alert> -->
      <div
        v-if="
          franchise.min_order && Object.keys(franchise.min_order).length > 0
        "
      >
        <div v-for="(field, i) in Object.keys(franchise.min_order)" :key="i">
          <v-alert
            color="blue-grey"
            outlined
            v-if="
              franchise.min_order[field].amount &&
              franchise.min_order[field].amount !== ''
            "
            :icon="
              franchise.min_order[field].app_to == 'price'
                ? 'mdi-currency-usd'
                : 'mdi-numeric'
            "
          >
            <b class="text-uppercase">{{ field }}</b> is available with a
            minimum order
            {{
              franchise.min_order[field].app_to == "price"
                ? `of RM${currency(franchise.min_order[field].amount)}`
                : `of ${franchise.min_order[field].amount} items`
            }}
          </v-alert>
        </div>
      </div>
    </div>
    <component :is="recent.component" />
    <transition name="fade">
      <component
        v-if="!searchWord"
        :is="
          navigation[
            franchise.list_style == 'list'
              ? 0
              : franchise.list_style == ''
              ? 1
              : 2
          ].component
        "
      />
    </transition>
    <v-alert
      color="blue-grey"
      outlined
      icon="mdi-alpha-p-circle"
      v-if="franchise.policy && franchise.policy !== ''"
    >
      {{ franchise.policy }}
    </v-alert>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Product",

  data() {
    return {
      out: null,
      dialog: false,
      selectedOutlet: "",
      isLoading: false,
      num: 0,
      view: "list",
      splited: null,
      navigation: [
        { title: "list", component: () => import("./List") },
        { title: "grid", component: () => import("./Grid") },
        { title: "slide", component: () => import("./Slide") },
      ],
      recent: { component: () => import("./Recent") },
      searchWord: "",
      searchMode: false,
      searched: [],
      searchProduct: [],
    };
  },

  computed: {
    ...mapGetters({
      franchise: "getFranchise",
      product: "getProduct",
      cart: "getCart",
      isOutlet: "getSelectedOutlet",
      scroll: "getScroll",
    }),
    tag_list() {
      var tags = {};
      this.product.forEach((prod) => {
        prod.tags.forEach((tag) => (tags[tag.id] = tag));
      });
      return Object.values(tags).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    currency() {
      return require("currency.js");
    },
    outlets() {
      let l = this.franchise.outlets.filter((d) => d.address);
      return l.map((d) => d.name);
    },
  },

  methods: {
    toCart() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "cart" },
      });
    },

    change(i, allow) {
      this.isLoading = true;
      if (!allow && this.cart.length !== 0) {
        this.isLoading = false;
        this.dialog = true;
        this.selectedOutlet = i;
      } else {
        this.dialog = false;
        this.$store.dispatch("updateCart", []);
        this.$store.dispatch("updateSO", i);
        this.out = i;
        let a = this.franchise.outlets.findIndex((d) => d.name == i);
        this.$store.dispatch("changeOutlet", a).then(() => {
          this.isLoading = false;
        });
      }
    },

    toSearch() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "search" },
      });
    },
    searchIsActive() {
      this.searchMode = !this.searchMode;
      this.searchWord = "";
      this.searchProduct = [];
    },

    SP() {
      if (this.searched.length > 5) {
        return this.searched.slice(Math.max(this.searched.length - 5, 1));
      } else return this.searched;
    },

    search() {
      if (this.searchWord == "") {
        this.searchProduct = [];
      } else {
        this.searchProduct = this.product.filter((prod) => {
          var query = this.searchWord.toLowerCase();
          if (prod.name.toLowerCase().includes(query)) return true;
          return false;
        });
      }
    },

    findSearch(v) {
      this.searchWord = v;
      this.search();
    },

    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },

    toProducts() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "products" },
      });
    },

    selectProduct(i) {
      if (this.searchWord !== "") {
        this.searched.push(this.searchWord);
        localStorage.setItem("search", JSON.stringify(this.searched));
      }
      this.$store.dispatch("updateSP", i);
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "product" },
      });
    },
  },

  async beforeMount() {
    window.scroll(0, 0);
    if (this.franchise.outlets.length > 0) {
      if (this.isOutlet) {
        this.out = this.isOutlet;
      } else {
        let l = this.franchise.outlets.filter((d) => d.address);
        this.out = l[0].name;

        await this.$store.dispatch("updateSO", l[0].name);
        let a = this.franchise.outlets.findIndex((d) => d.name == l[0].name);
        await this.$store.dispatch("changeOutlet", a).then(() => {
          this.isLoading = false;
        });
      }
    }

    if (this.franchise.address) {
      let c = await this.$store.dispatch("fetchGeocode", this.franchise);
      this.splited = c.city + ", " + c.state;
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
